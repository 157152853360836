<div class="container-fluid">
  <div class="row text-center align-items-center subsection-header mb-4">
    <span>{{'event.administration' | translate}}</span>
  </div>
  <div *ngIf="(authService.signedInChanged$ | async)" class="row text-center username-container mb-4">
    <div>
      <span>{{'event.username' | translate}}:</span><span class="username"> {{getEmail()}}</span>
    </div>
  </div>
  <div class="row justify-content-center flex-column" *ngIf="sponsorEventCompanyOverview">
    <div *ngIf="!(breakObserverService.max890$ | async).matches && !(breakObserverService.max1280$ | async).matches">
      <div class="numbers-barometer-container mx-auto">
        <div class="numbers-container d-flex">
          <div class="number-container text-center">
            <div>
              <div class="number">
                {{sponsorEventCompanyOverview.sponsorCount | number :'':customTranslationService.getLang()}}
              </div>
              <div class="number-text">
                {{'event.sponsors' | translate}}
              </div>
            </div>
          </div>
          <div class="number-container text-center">
            <div class="number">
              {{sponsorEventCompanyOverview.totalMoney | number :'':customTranslationService.getLang()}}
            </div>
            <div class="number-text">
              {{'event.total' | translate}}
            </div>
          </div>
          <div class="number-container text-center">
            <div class="number">
              {{sponsorEventCompanyOverview.userCount | number :'':customTranslationService.getLang()}}
            </div>
            <div class="number-text">
              {{'event.participants' | translate}}
            </div>
          </div>
        </div>
        <div class="barometer">
          <img class="barometer-needle" src="assets/event/needle.png" style="{{calculateRotation()}}"/>
        </div>
      </div>
      <div class="event-name-container mx-auto">
        <div class="event-name text-center">
          <div class="event-name-title">{{event?.name}}</div>
          <div class="event-name-arranged-by">
            {{'event.arrangedBy' | translate}} <span style="font-weight: 800">{{event?.company?.name}}</span>
          </div>
        </div>
        <div class="event-total-container">
          <div class="event-total-text-container">
            <div class="event-total-number">{{sponsorEventCompanyOverview.sponsorEvent.target | number :'':customTranslationService.getLang()}} KR.</div>
            <div class="event-total-text">{{'event.goal' | translate}}</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!(breakObserverService.max890$ | async).matches && (breakObserverService.max1280$ | async).matches">
      <div class="numbers-barometer-container-mid mx-auto">
        <div class="barometer-mid">
          <img class="barometer-needle-mid" src="assets/event/needle.png" style="{{calculateRotation()}}"/>
        </div>
        <div class="event-total-container-mid">
          <div class="event-total-text-container">
            <div class="event-total-number">{{sponsorEventCompanyOverview.sponsorEvent.target | number :'':customTranslationService.getLang()}} KR.</div>
            <div class="event-total-text">{{'event.goal' | translate}}</div>
          </div>
        </div>
        <div class="numbers-container-mid d-flex mt-4">
          <div class="number-container-mid text-center">
            <div>
              <div class="number-mid">
                {{sponsorEventCompanyOverview.sponsorCount | number :'':customTranslationService.getLang()}}
              </div>
              <div class="number-text-mid">
                {{'event.sponsors' | translate}}
              </div>
            </div>
          </div>
          <div class="number-container-mid text-center">
            <div class="number-mid">
              {{sponsorEventCompanyOverview.totalMoney | number :'':customTranslationService.getLang()}}
            </div>
            <div class="number-text-mid">
              {{'event.total' | translate}}
            </div>
          </div>
          <div class="number-container-mid text-center">
            <div class="number">
              {{sponsorEventCompanyOverview.userCount | number :'':customTranslationService.getLang()}}
            </div>
            <div class="number-text-mid">
              {{'event.participants' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="event-name-container-mid mx-auto">
        <div class="event-name-mid text-center">
          <div class="event-name-title-mid">{{event?.name}}</div>
          <div class="event-name-arranged-by-mid">
            {{'event.arrangedBy' | translate}} <span style="font-weight: 800">{{event?.company?.name}}</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(breakObserverService.max890$ | async).matches && (breakObserverService.max1280$ | async).matches">
      <div class="numbers-barometer-container-small mx-auto">
        <div class="barometer-small">
          <img class="barometer-needle-small" src="assets/event/needle.png" style="{{calculateRotation()}}"/>
        </div>
        <div class="event-total-container-small">
          <div class="event-total-text-container">
            <div class="event-total-number">{{sponsorEventCompanyOverview.sponsorEvent.target | number :'':customTranslationService.getLang()}} KR.</div>
            <div class="event-total-text">{{'event.goal' | translate}}</div>
          </div>
        </div>
        <div class="numbers-container-small d-flex flex-column mt-4">
          <div class="number-container-small text-center">
            <div>
              <div class="number-small">
                {{sponsorEventCompanyOverview.sponsorCount | number :'':customTranslationService.getLang()}}
              </div>
              <div class="number-text-small">
                {{'event.sponsors' | translate}}
              </div>
            </div>
          </div>
          <div class="number-container-small text-center">
            <div class="number-small">
              {{sponsorEventCompanyOverview.totalMoney | number :'':customTranslationService.getLang()}}
            </div>
            <div class="number-text-small">
              {{'event.total' | translate}}
            </div>
          </div>
          <div class="number-container-small text-center">
            <div class="number">
              {{sponsorEventCompanyOverview.userCount | number :'':customTranslationService.getLang()}}
            </div>
            <div class="number-text-small">
              {{'event.participants' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="event-name-container-small mx-auto">
        <div class="event-name-small text-center">
          <div class="event-name-title-small">{{event?.name}}</div>
          <div class="event-name-arranged-by-small">
            <div>
              {{'event.arrangedBy' | translate}}
            </div>
            <div>
              <span style="font-weight: 800">{{event?.company?.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="row mt-5">
        <div class="col-md-4 ps-0 pe-0 sidebar">
          <div class="ms-auto ms-4 pt-3 sidebar-group">
            <div class="sidebar-header text-uppercase">{{'event.eventProfile' | translate}}</div>
            <div *ngIf="event?.isAdmin">
              <a class="sidebar-item" [ngClass]="isTabActive('tab-company-data') ? 'sidebar-item-active' : ''" (click)="selectTab('tab-company-data')">{{'event.companyData' | translate}}</a>
            </div>
            <div *ngIf="event?.isAdmin">
              <a class="sidebar-item" [ngClass]="isTabActive('tab-event-terms') ? 'sidebar-item-active' : ''" (click)="selectTab('tab-event-terms')">{{'event.terms' | translate}}</a>
            </div>
            <div *ngIf="event?.isAdmin">
              <a class="sidebar-item" [ngClass]="isTabActive('tab-event-user-admin') ? 'sidebar-item-active' : ''" (click)="selectTab('tab-event-user-admin')">{{'event.userAdmin' | translate}}</a>
            </div>
            <div *ngIf="event?.isAdmin">
              <a class="sidebar-item" [ngClass]="isTabActive('tab-all-sponsor-run-users-admin') ? 'sidebar-item-active' : ''" (click)="selectTab('tab-all-sponsor-run-users-admin')">{{'event.allParticipants' | translate}}</a>
            </div>
            <div *ngIf="event?.isAdmin">
              <a class="sidebar-item" [ngClass]="isTabActive('tab-all-sponsors-admin') ? 'sidebar-item-active' : ''" (click)="selectTab('tab-all-sponsors-admin')">{{'event.allSponsors' | translate}}</a>
            </div>
            <div *ngIf="event?.isAdmin">
              <a class="sidebar-item" download="{{event.name}}.xlsx" (click)="getExcelSummary()">{{'event.excel' | translate}}</a>
            </div>
            <div>
              <a class="sidebar-item" [ngClass]="isTabActive('tab-event') ? 'sidebar-item-active' : ''" (click)="selectTab('tab-event')">{{'event.ourEvent' | translate}}</a>
            </div>
          </div>
          <div class="ms-auto ms-4 pt-3 pb-3 sidebar-group">
            <div class="sidebar-header text-uppercase">{{'event.eventActivity' | translate}}</div>
            <div>
              <a class="sidebar-item" [ngClass]="isTabActive('tab-event-sponsor-run-users') ? 'sidebar-item-active' : ''" (click)="selectTab('tab-event-sponsor-run-users')">{{'event.seeParticipants' | translate}}</a>
            </div>
            <div>
              <a class="sidebar-item" [ngClass]="isTabActive('tab-event-sponsors') ? 'sidebar-item-active' : ''" (click)="selectTab('tab-event-sponsors')">{{'event.seeSponsors' | translate}}</a>
            </div>
            <div>
              <a class="sidebar-item" *ngIf="(authService.signedInChanged$ | async) && utilService.dateAfterNow(event?.endDate)" [ngClass]="isTabActive('tab-event-latest-event-registrations') ? 'sidebar-item-active' : ''" (click)="selectTab('tab-event-latest-event-registrations')">{{'event.latestRegistrations' | translate}}</a>
            </div>
            <div>
              <a class="sidebar-item" *ngIf="utilService.dateAfterNow(event?.endDate)" [ngClass]="isTabActive('tab-event-invite-participant') ? 'sidebar-item-active' : ''" (click)="selectTab('tab-event-invite-participant')">{{'event.inviteParticipant' | translate}}</a>
            </div>
            <div *ngIf="(authService.signedInChanged$ | async)">
              <a class="sidebar-item"*ngIf="utilService.dateAfterNow(event?.endDate)"[ngClass]="isTabActive('tab-event-invite-sponsor') ? 'sidebar-item-active' : ''" (click)="selectTab('tab-event-invite-sponsor')">{{'event.inviteSponsor' | translate}}</a>
            </div>
            <div *ngIf="sponsorRunUser">
              <a class="sidebar-item" [ngClass]="isTabActive('tab-event-register-activity') ? 'sidebar-item-active' : ''" (click)="selectTab('tab-event-register-activity')">{{'event.registerActivity' | translate}}</a>
            </div>
          </div>
          <!--        <div>-->
          <!--          nice to have-->
          <!--          » Udsend nyhedsmail-->
          <!--        </div>-->
        </div>
        <div class="col-md-8 ps-0 pe-0">
          <eventShop-tabs>
            <eventShop-tab [name]="'tab-event'">
              <app-tab-event [event]="event" [sponsorEventCompanyOverview]="sponsorEventCompanyOverview"></app-tab-event>
            </eventShop-tab>
            <eventShop-tab [name]="'tab-event-terms'" *ngIf="event?.isAdmin">
              <app-event-terms></app-event-terms>
            </eventShop-tab>
            <eventShop-tab [name]="'tab-company-data'" *ngIf="event?.isAdmin">
              <app-tab-company-data [company]="event?.company"></app-tab-company-data>
            </eventShop-tab>
            <eventShop-tab [name]="'tab-event-sponsors'">
              <app-tab-event-sponsors [sponsorEventCompanyOverviewResponse]="sponsorEventCompanyOverview" (showSponsored)="showSponsored($event)"></app-tab-event-sponsors>
            </eventShop-tab>
            <eventShop-tab [name]="'tab-event-sponsor-run-users'">
              <app-tab-event-sponsor-run-users [sponsorRunUserSummaries]="sponsorEventCompanyOverview?.sponsorRunUserSummaries"></app-tab-event-sponsor-run-users>
            </eventShop-tab>
            <eventShop-tab [name]="'tab-event-latest-event-registrations'">
              <app-tab-event-latest-event-registrations [eventId]="event?.id"></app-tab-event-latest-event-registrations>
            </eventShop-tab>
            <eventShop-tab [name]="'tab-event-user-admin'" *ngIf="event?.isAdmin">
              <app-tab-event-user-admin [eventId]="event?.id"></app-tab-event-user-admin>
            </eventShop-tab>
            <eventShop-tab [name]="'tab-event-invite-sponsor'">
              <app-tab-event-invite-sponsor [eventId]="event?.id"></app-tab-event-invite-sponsor>
            </eventShop-tab>
            <eventShop-tab [name]="'tab-event-invite-participant'">
              <app-tab-event-invite-participant [event]="event"></app-tab-event-invite-participant>
            </eventShop-tab>
            <eventShop-tab [name]="'tab-event-edit'">
              <app-tab-event-edit [event]="event"></app-tab-event-edit>
            </eventShop-tab>
            <eventShop-tab [name]="'tab-event-register-activity'">
              <app-tab-event-register-activity [event]="event" [sponsorRunUser]="sponsorRunUser"></app-tab-event-register-activity>
            </eventShop-tab>
            <eventShop-tab [name]="'tab-all-sponsor-run-users-admin'" *ngIf="event?.isAdmin">
              <app-tab-all-sponsor-run-users-admin [adminShownSponsorRunUserSummaries]="adminSponsorEventCompanyOverview" [event]="event" (showSponsorRunnerDetails)="showSponsorRunDetails($event)"></app-tab-all-sponsor-run-users-admin>
            </eventShop-tab>
            <eventShop-tab [name]="'tab-all-sponsors-admin'" *ngIf="event?.isAdmin">
              <app-tab-all-sponsors-admin [adminShownSponsorRunUserSummaries]="adminSponsorEventCompanyOverview" (showSponsored)="showSponsored($event)"></app-tab-all-sponsors-admin>
            </eventShop-tab>
          </eventShop-tabs>
        </div>
      </div>
    </div>
  </div>
  <ng-template #sponsored>
    <div class="sponsored-modal-header">
      <h5 class="sponsored-modal-title">{{'event.sponsoredModal.title' | translate}}</h5>
    </div>
    <div class="modal-body">
      <div class="table-responsive">
        <table class="sponsored-modal-table table">
          <thead>
          <tr>
            <td>{{'event.sponsoredModal.tableHeaders.name' | translate}}</td>
            <td>{{'event.sponsoredModal.tableHeaders.activity' | translate}}</td>
            <td>{{'event.sponsoredModal.tableHeaders.perUnit' | translate}}</td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let sponsorRankSummary of sponsorRankSummaries">
            <td>{{sponsorRankSummary.name}}</td>
            <td colspan="2">
              <table class="inline-sponsored-modal-table">
                <tr>
                  <td style="padding-left: 0">
                    {{'global.sponsorParticipateTypeItem.' + sponsorRankSummary.sponsorParticipationType | translate}}
                  </td>
                  <td>
                    <div>
                      {{sponsorRankSummary.paymentPerUnit| number :'':customTranslationService.getLang()}}kr/{{'global.unitTypeItem.' + sponsorRankSummary.unitType | translate}}
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="cancelButtonPressed()">{{'modal.ok' | translate}}</button>
    </div>
  </ng-template>
  <ng-template #sponsorRunPaymentDetails>
    <div class="sponsored-modal-header">
      <h5 class="sponsored-modal-title">{{'event.ShowSponsorRunDetailsModal.title' | translate}}</h5>
    </div>
    <div class="modal-body" *ngIf="sponsorRunUserSummaryDto">
      <h1>{{sponsorRunUserSummaryDto.name}}</h1>
      <div class="table-responsive">
        <table class="participant-modal-table table">
          <thead>
          <tr>
            <td>{{'event.ShowSponsorRunDetailsModal.tableHeaders.entryFee' | translate}}</td>
            <td>{{'event.ShowSponsorRunDetailsModal.tableHeaders.totalUserMoneyToBePayed' | translate}}</td>
            <td>{{'event.ShowSponsorRunDetailsModal.tableHeaders.maxUserPayment' | translate}}</td>
            <td>{{'event.ShowSponsorRunDetailsModal.tableHeaders.totalSponsorMoney' | translate}}</td>
            <td>{{'event.ShowSponsorRunDetailsModal.tableHeaders.singlePayment' | translate}}</td>
            <td>{{'event.ShowSponsorRunDetailsModal.tableHeaders.total' | translate}}</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{sponsorRunUserSummaryDto.entryFee | number: '': customTranslationService.getLang()}} kr</td>
            <td>{{getSponsorRunPayment(sponsorRunUserSummaryDto) | number: '': customTranslationService.getLang()}} kr</td>
            <td>{{sponsorRunUserSummaryDto.maxUserPayment | number: '': customTranslationService.getLang()}} kr</td>
            <td>{{sponsorRunUserSummaryDto.totalSponsorMoney| number :'':customTranslationService.getLang()}} kr</td>
            <td>{{sponsorRunUserSummaryDto.singlePayment | number: '': customTranslationService.getLang()}} kr</td>
            <td>{{getTotalPayment(sponsorRunUserSummaryDto) | number :'':customTranslationService.getLang()}} kr</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-4" *ngFor="let userUnitTypeSummary of sponsorRunUserSummaryDto.userUnitTypeSummaries">
        <h2>{{'global.sponsorParticipateTypeItem.' + userUnitTypeSummary.sponsorParticipationType | translate}}</h2>
        <div class="table-responsive">
          <table class="participant-modal-table table">
            <thead>
            <tr>
              <td>{{'event.ShowSponsorRunDetailsModal.tableHeaders.name' | translate}}</td>
              <td>{{'event.ShowSponsorRunDetailsModal.tableHeaders.perUnit' | translate}}</td>
              <td>{{'event.ShowSponsorRunDetailsModal.tableHeaders.max' | translate}}</td>
              <td>{{'event.ShowSponsorRunDetailsModal.tableHeaders.activity' | translate}}</td>
              <td>{{'event.ShowSponsorRunDetailsModal.tableHeaders.total' | translate}}</td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                {{sponsorRunUserSummaryDto.name}}
              </td>
              <td>
                {{(userUnitTypeSummary.userMoneyPerUnit)| number :'':customTranslationService.getLang()}} kr / {{'global.unitTypeItem.' + userUnitTypeSummary.unitType | translate}}
              </td>
              <td>
                {{(sponsorRunUserSummaryDto.maxUserPayment == null ? ('global.noCap' | translate) : (sponsorRunUserSummaryDto.maxUserPayment | number: '': customTranslationService.getLang()) + 'kr')}}
              </td>
              <td>
                {{userUnitTypeSummary.totalUnitCount| number :'':customTranslationService.getLang()}} {{'global.unitTypeItem.' + userUnitTypeSummary.unitType | translate}}
              </td>
              <td>
                {{userUnitTypeSummary.totalUserMoney| number :'':customTranslationService.getLang()}} kr
              </td>
            </tr>
            <tr *ngFor="let sponsor of userUnitTypeSummary.summaryPerSponsorList" [ngClass]="utilService.getPayedClassFromSponsor(sponsor)">
              <td>{{getSponsorName(sponsor)}}</td>
              <td>{{sponsor.sponsorMoneyPerUnit | number: '': customTranslationService.getLang()}} kr / {{'global.unitTypeItem.' + userUnitTypeSummary.unitType | translate}}</td>
              <td>{{(sponsor.sponsorCap === 0 ? ('global.noCap' | translate) : (sponsor.sponsorCap | number: '': customTranslationService.getLang()) + 'kr')}}</td>
              <td>
                {{userUnitTypeSummary.totalUnitCount| number :'':customTranslationService.getLang()}} {{'global.unitTypeItem.' + userUnitTypeSummary.unitType | translate}}
              </td>
              <td>{{sponsor.totalSponsorMoney | number: '': customTranslationService.getLang()}} kr</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="cancelButtonPressed()">{{'modal.ok' | translate}}</button>
    </div>
  </ng-template>
</div>
