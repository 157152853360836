import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SponsorRunService } from '../../services/sponsor-run.service';
import { SponsorLink } from '../../models/sponsor-link';
import { Consts } from '../../consts';
import { first } from 'rxjs/operators';
import { ToastService } from '../../services/toast.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'shared';

@Component({
  selector: 'app-tab-event-invite-sponsor',
  templateUrl: './tab-event-invite-sponsor.component.html',
  styleUrls: ['./tab-event-invite-sponsor.component.scss']
})
export class TabEventInviteSponsorComponent implements OnInit, OnChanges {
  @Input()
  public eventId: number;

  public sponsorLink: SponsorLink;

  private subscriptions = new Subscription();

  constructor(private sponsorService: SponsorRunService, private toastService: ToastService, private authService: AuthService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  ngOnChanges(changes): void {
    this.subscriptions.add(this.authService.signedInChanged$.subscribe(signedIn => {
      if (changes["eventId"] && this.eventId) {
        this.sponsorService.getSponsorLink(this.eventId).pipe(first())
          .subscribe(s => this.sponsorLink = s);
      }
    }));
  }

  public getSponsorLinkUrl(): string {
    return `${window.location.protocol}//${window.location.host}/${Consts.sponsorLinkRoute}/${this.sponsorLink?.link}`;
  }

  public copied($event: any) {
    this.toastService.showToast('toast.copied', 'toast.link');
  }
}
