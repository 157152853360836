<div class="container-fluid bigger-body-font-size">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <div class="item-header">
        <h1 class="big-header-black">{{'user.sponsorships' | translate}}</h1>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5 justify-content-center">
    <div class="col-md-10">
      <div *ngIf="(breakObserverService.tableSmaller$ | async).matches">
        <table class="mobile-list-table mb-5" *ngFor="let sponsorSummery of (sponsorSummeries$ | async), let i = index">
          <tbody [class]="i % 2 == 0 ? 'even' : ''">
          <tr>
            <td class="td-date">{{'sponsoredTable.headers.startDate' | translate}}</td>
            <td class="td-date">{{sponsorSummery.sponsorEvent.startDate | date: utilService.getDateFormat()}}</td>
          </tr>
          <tr>
            <td class="td-date">{{'sponsoredTable.headers.endDate' | translate}}</td>
            <td class="td-date">{{sponsorSummery.sponsorEvent.endDate | date: utilService.getDateFormat()}}</td>
          </tr>
          <tr>
            <td class="td-name">{{'sponsoredTable.headers.event' | translate}}</td>
            <td class="td-name">{{sponsorSummery.sponsorEvent.eventName}}</td>
          </tr>
          <tr>
            <td class="td-rest">{{'sponsoredTable.headers.user' | translate}}</td>
            <td class="td-rest">{{sponsorSummery.sponsoredUserName}}</td>
          </tr>
          <tr>
            <td class="td-rest">{{'sponsoredTable.headers.type' | translate}}</td>
            <td class="td-rest">{{'global.sponsorParticipateTypeItem.' + sponsorSummery.sponsorParticipationType | translate}} - {{'global.unitTypeItem.' + sponsorSummery.unitType | translate}}</td>
          </tr>
          <tr>
            <td class="td-rest">{{'sponsoredTable.headers.money' | translate}}</td>
            <td class="td-rest">{{sponsorSummery.sponsorMoney}}</td>
          </tr>
          <tr>
            <td colspan="2" class="pt-4">
              <div>
                <a routerLink="{{goToEvent(sponsorSummery.sponsorEvent.eventId)}}">
                  {{'global.goToEvent' | translate}}
                </a>
              </div>

              <div *ngIf="utilService.dateAfterNow(sponsorSummery.sponsorEvent.endDate)">
                {{'global.eventNotFinished' | translate}}
              </div>
              <div *ngIf="utilService.dateBeforeNow(sponsorSummery.sponsorEvent.endDate)">
                <div *ngIf="sponsorSummery.isSponsorshipPayed">
                  {{'sponsoredTable.payed' | translate}}
                </div>

                <div
                  *ngIf="!sponsorSummery.approved">
                  {{'sponsoredTable.notApproved' | translate}}
                </div>
<!--                <div>-->
<!--                  <a routerLink="{{goToPayment(sponsorSummery)}}" *ngIf="sponsorSummery.approved && !sponsorSummery.isSponsorshipPayed">-->
<!--                    {{'global.pay' | translate}}-->
<!--                  </a>-->
<!--                </div>-->
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <table *ngIf="!(breakObserverService.tableSmaller$ | async).matches">
        <thead>
        <tr>
          <td class="td-date">{{'sponsoredTable.headers.startDate' | translate}}</td>
          <td class="td-date">{{'sponsoredTable.headers.endDate' | translate}}</td>
          <td class="td-name">{{'sponsoredTable.headers.event' | translate}}</td>
          <td class="td-rest">{{'sponsoredTable.headers.user' | translate}}</td>
          <td class="td-rest">{{'sponsoredTable.headers.type' | translate}}</td>
          <td class="td-rest">{{'sponsoredTable.headers.money' | translate}}</td>
          <td></td>
          <td></td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let sponsorSummery of (sponsorSummeries$ | async)">
          <td class="td-date">{{sponsorSummery.sponsorEvent.startDate | date: utilService.getDateFormat()}}</td>
          <td class="td-date">{{sponsorSummery.sponsorEvent.endDate | date: utilService.getDateFormat()}}</td>
          <td class="td-name">{{sponsorSummery.sponsorEvent.eventName}}</td>
          <td class="td-rest">{{sponsorSummery.sponsoredUserName}}</td>
          <td class="td-rest">{{'global.sponsorParticipateTypeItem.' + sponsorSummery.sponsorParticipationType | translate}} - {{'global.unitTypeItem.' + sponsorSummery.unitType | translate}}</td>
          <td class="td-rest">{{sponsorSummery.sponsorMoney}}</td>
          <td class="text-end td-link">
            <a routerLink="{{goToEvent(sponsorSummery.sponsorEvent.eventId)}}">
              {{'global.goToEvent' | translate}}
            </a>
          </td>
          <td *ngIf="utilService.dateAfterNow(sponsorSummery.sponsorEvent.endDate)" class="text-end td-link">
            {{'global.eventNotFinished' | translate}}
          </td>
          <td *ngIf="utilService.dateBeforeNow(sponsorSummery.sponsorEvent.endDate)" class="text-end td-link">
            <span *ngIf="sponsorSummery.isSponsorshipPayed">
              {{'sponsoredTable.payed' | translate}}
            </span>

            <span *ngIf="!sponsorSummery.approved">
              {{'sponsoredTable.notApproved' | translate}}
            </span>

            <a routerLink="{{goToPayment(sponsorSummery)}}" *ngIf="sponsorSummery.approved && !sponsorSummery.isSponsorshipPayed">
              {{'global.pay' | translate}}
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
ˆ
